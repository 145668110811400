.front-aside {
  border-right: 3px solid #051121;
  background-color: #0e2342;
  height: 100vh;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}
.front-aside .title-row {
    overflow: hidden;
    padding-left: 1.04167vw;
    padding-top: 3.7963vh;
    padding-right: 1.14583vw;
    height: 13.98148vh;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
}
.front-aside .title-row .my-mission {
      float: left;
      color: #fff;
      font-size: 24px;
      font-family: Source Han Sans CN;
      font-weight: bold;
      color: #feffff;
      line-height: 40px;
}
@media screen and (max-width: 1440px) and (min-width: 1367px) {
.front-aside .title-row .my-mission {
          font-size: 21px;
}
}
@media screen and (max-width: 1366px) {
.front-aside .title-row .my-mission {
          font-size: 18px;
}
}
.front-aside .title-row .my-button {
      float: right;
}
.front-aside .title-row .my-button .el-button {
        border: 2px solid #a6c1dc;
        background-color: transparent;
        color: #fff;
        padding: 10px;
}
.front-aside .title-row .my-button .el-button.selected {
        background-color: #a6c1dc;
}
.front-aside .title-row .my-button .new-icon {
        margin-left: 11px;
        width: 2.08333vw;
        vertical-align: middle;
}
.front-aside .title-row .my-button .new-icon img {
          width: 100%;
}
.front-aside .flow-left .flow-item {
    border-right: none;
}
.front-aside .list-wrapper {
    overflow-y: scroll;
    -webkit-box-flex: 1;
        -ms-flex: 1;
            flex: 1;
}
.front-main {
  padding: 0;
}
